var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"700px","transition":"slide-y-transition"},model:{value:(_vm.addEditDialog),callback:function ($$v) {_vm.addEditDialog=$$v},expression:"addEditDialog"}},[_c('v-card',{staticClass:"dialog-card-border"},[_c('v-card-title',{staticClass:"text-h6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.editMode ? _vm.$t('Update Shareholder') : _vm.$t('Add New Shareholder'))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',{staticClass:"mdil-36px mdil-rotate-45"},[_vm._v(" mdil-plus ")])],1)],1),_c('validation-observer',{ref:"theForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-14",staticStyle:{"position":"relative"},attrs:{"cols":"12"}},[(_vm.addEditDialog)?_c('app-image-uploader',{attrs:{"width":"100%","height":"150px","label":"Profile picture","parent-image-url":_vm.formData.cover_picture},on:{"image-selected":function($event){_vm.formData.cover_picture = $event},"is-image-updated":function($event){_vm.formData.cover_image_updated = $event}}}):_vm._e(),(_vm.addEditDialog)?_c('app-image-uploader',{attrs:{"width":"115px","height":"115px","classes":"rounded-pill","parent-classes":"justify-center","parent-style":"position: absolute; bottom: -50px; left: 50%; transform: translateX(-50%)","label":"Profile picture","parent-image-url":_vm.formData.profile_picture},on:{"image-selected":function($event){_vm.formData.profile_picture = $event},"is-image-updated":function($event){_vm.formData.profile_image_updated = $event}}}):_vm._e()],1),_c('v-col',{staticClass:"px-1 pb-3 pt-4",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Shareholder Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('Shareholder Name'))),"outlined":"","dense":"","hint":_vm.$t('This name use to login'),"persistent-hint":"","error-messages":errors},model:{value:(_vm.formData.username),callback:function ($$v) {_vm.$set(_vm.formData, "username", $$v)},expression:"formData.username"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Full Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('Full Name'))),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.first_name),callback:function ($$v) {_vm.$set(_vm.formData, "first_name", $$v)},expression:"formData.first_name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Share Percentage %'),"rules":"required|max_value:100|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-currency-field',{attrs:{"label":("* " + (_vm.$t('Share Percentage %'))),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.share_percentage),callback:function ($$v) {_vm.$set(_vm.formData, "share_percentage", $$v)},expression:"formData.share_percentage"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Phone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('Phone'))),"outlined":"","dense":"","type":"tel","error-messages":errors},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"label":("* " + (_vm.$t('Type'))),"items":_vm.shareholderTypesList,"outlined":"","dense":"","error-messages":errors},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('Press enter to create to record'))+" ")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('ID Cart'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('ID Cart'))),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.id_cart),callback:function ($$v) {_vm.$set(_vm.formData, "id_cart", _vm._n($$v))},expression:"formData.id_cart"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Occupation')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Occupation'),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.occupation),callback:function ($$v) {_vm.$set(_vm.formData, "occupation", $$v)},expression:"formData.occupation"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Degree')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Degree'),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.degree),callback:function ($$v) {_vm.$set(_vm.formData, "degree", $$v)},expression:"formData.degree"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Nickname')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Nickname'),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.nickname),callback:function ($$v) {_vm.$set(_vm.formData, "nickname", $$v)},expression:"formData.nickname"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Business Name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Business Name'),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.business_name),callback:function ($$v) {_vm.$set(_vm.formData, "business_name", $$v)},expression:"formData.business_name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Martial Status')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mt-0",attrs:{"row":"","error-messages":errors},model:{value:(_vm.formData.marital_status),callback:function ($$v) {_vm.$set(_vm.formData, "marital_status", $$v)},expression:"formData.marital_status"}},[_c('v-radio',{attrs:{"label":_vm.$t('Single'),"value":1,"ripple":false}}),_c('v-radio',{attrs:{"label":_vm.$t('Married'),"value":2,"ripple":false}})],1)]}}],null,true)})],1),(_vm.formData.marital_status == 2)?_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Husband/Wife Name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Husband/Wife Name'),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.spouse_name),callback:function ($$v) {_vm.$set(_vm.formData, "spouse_name", $$v)},expression:"formData.spouse_name"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Mother Name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Mother Name'),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.mother_name),callback:function ($$v) {_vm.$set(_vm.formData, "mother_name", $$v)},expression:"formData.mother_name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":_vm.formData.marital_status == 2 ? 12 : 6,"md":_vm.formData.marital_status == 2 ? 12 : 6,"lg":_vm.formData.marital_status == 2 ? 12 : 6}},[_c('validation-provider',{attrs:{"name":_vm.$t('Dos Number Tax Office')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Dos Number Tax Office'),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.dos_number_tax_office),callback:function ($$v) {_vm.$set(_vm.formData, "dos_number_tax_office", $$v)},expression:"formData.dos_number_tax_office"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Address')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('Address'),"outlined":"","rows":"3","dense":"","no-resize":"","error-messages":errors},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}})]}}],null,true)})],1),(_vm.formData.marital_status == 2)?_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"py-1 rounded-sm mb-4",staticStyle:{"border":"1px solid #ccc"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-2 d-none d-sm-flex",attrs:{"cols":"12","sm":"4","md":"4","lg":"4"}},[_vm._v(" "+_vm._s(_vm.$t('Child Name'))+" ")]),_c('v-col',{staticClass:"px-2 d-none d-sm-flex",attrs:{"cols":"12","sm":"4","md":"4","lg":"4"}},[_vm._v(" "+_vm._s(_vm.$t('Date of Birth'))+" ")]),_c('v-col',{staticClass:"px-2 d-none d-sm-flex",attrs:{"cols":"12","sm":"3","md":"3","lg":"3"}},[_vm._v(" "+_vm._s(_vm.$t('Occupation'))+" ")]),_c('v-col',{staticClass:"px-2 d-flex d-sm-none",attrs:{"cols":"10"}},[_vm._v(" "+_vm._s(_vm.$t('Children'))+" ")]),_c('v-col',{staticClass:"px-2 text-end",attrs:{"cols":"2","sm":"1","md":"1","lg":"1"}},[_c('v-icon',{attrs:{"color":"success"},on:{"click":function($event){return _vm.addRow()}}},[_vm._v(" mdil-plus-box ")])],1)],1),_vm._l((_vm.formData.babies),function(baby,index){return _c('v-row',{key:index,attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"4","md":"4","lg":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Child Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Child Name'),"outlined":"","dense":"","error-messages":errors},model:{value:(baby.name),callback:function ($$v) {_vm.$set(baby, "name", $$v)},expression:"baby.name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"4","md":"4","lg":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Birth Date')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('app-date-picker',{attrs:{"label":_vm.$t('Birth Date'),"outlined":"","dense":"","is-dob":"","errors":errors},model:{value:(baby.dob),callback:function ($$v) {_vm.$set(baby, "dob", $$v)},expression:"baby.dob"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"11","sm":"3","md":"3","lg":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Occupation')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Occupation'),"outlined":"","dense":"","error-messages":errors},model:{value:(baby.occupation),callback:function ($$v) {_vm.$set(baby, "occupation", $$v)},expression:"baby.occupation"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1 pt-2 text-end",attrs:{"cols":"1","sm":"1","md":"1","lg":"1"}},[_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.removeRow()}}},[_vm._v(" mdil-delete ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(index != _vm.formData.babies.length - 1),expression:"index != formData.babies.length - 1"}],staticClass:"mb-5"})],1)],1)})],2):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3"},[_c('app-form-tip'),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","outlined":"","small":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","depressed":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdil-check ")]),_c('span',{staticClass:"pe-2"},[_vm._v(" "+_vm._s(_vm.editMode ? _vm.$t('Save') : _vm.$t('Add'))+" ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }